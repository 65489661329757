import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { FixedSizeList as List } from "react-window";
import "../../../static/assets/scss/components/searchable-select.scss";
import { formatCollection } from "./formatCollection";

const SearchableSelect = ({
  selectCollections,
  disabled,
  onSelectedOptionCollection,
  selectedOptionCollection,
}) => {
  // Memoize options to avoid recalculating on each render
  const options = useMemo(() => {
    return selectCollections.map((collection) => ({
      id: collection.cid,
      label: collection.name,
    }));
  }, [selectCollections]);

  const selectRef = useRef(null);

  // Open/Close list
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  // Search option in list
  const handleSearch = (event) => {
    onSelectedOptionCollection(null);
    if (disabled) return;

    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (!isOpen) {
      setIsOpen(true);
    }

    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(term),
    );

    setFilteredOptions(filtered);
  };

  // Reset input when disabled
  useEffect(() => {
    if (disabled) {
      setFilteredOptions(options); // Reset options if needed
      setIsOpen(false);
      onSelectedOptionCollection(null);
      setSearchTerm("");
    }
  }, [disabled, options]);

  const [searchTerm, setSearchTerm] = useState("");
  //Choice select option
  const handleSelect = (option) => {
    if (disabled) return;

    setSearchTerm(formatCollection(option.label, option.id));
    setIsOpen(false);
    onSelectedOptionCollection(option);
  };

  // Create list for select
  const Row = ({ index, style }) => (
    <li
      style={style}
      title={filteredOptions[index].label}
      onClick={() => handleSelect(filteredOptions[index])}
    >
      {formatCollection(
        filteredOptions[index].label,
        filteredOptions[index].id,
      )}
    </li>
  );
  Row.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className={`select-container ${isOpen ? "open" : ""}`} ref={selectRef}>
      <div
        className={`select-input-wrap ${
          disabled ? "select-input-wrap-disabled" : ""
        }`}
      >
        <input
          type="text"
          title={selectedOptionCollection?.label || ""}
          value={searchTerm || ""}
          onChange={handleSearch}
          onClick={() => !disabled && setIsOpen(!isOpen)}
          disabled={disabled}
          required={!disabled}
          placeholder={
            disabled
              ? "No Assigned Collection"
              : "Search...       (Required Field)"
          }
          className="select-input rb-input"
        />
      </div>
      {isOpen && (
        <ul className="select-dropdown">
          {filteredOptions.length > 0 ? (
            <List
              height={200}
              itemCount={filteredOptions.length}
              itemSize={35}
              width={300}
            >
              {Row}
            </List>
          ) : (
            <li>No Collections Found, click “+” to create one</li>
          )}
        </ul>
      )}
    </div>
  );
};

SearchableSelect.propTypes = {
  selectCollections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  onSelectedOptionCollection: PropTypes.func.isRequired,
  selectedOptionCollection: PropTypes.object,
};
export default SearchableSelect;

import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { motion } from "framer-motion";
import { fetchCreateNameCollection } from "../utils/apis";

const ConfirmationLoadingCollectionCreating = ({
  titleConfirmation = "",
  textConfirmation = "",
  titleLoading = "",
  textLoading = "",
  nameCollection = "",
  onNameCollectionError,
  onNameCollectionSuccess,
  onNameCollection = "",
  onShowConfirmationCollectionCreate,
  onUpdateStampQuota,
}) => {
  // Confirmation create Collection an show loading
  const [loading, setLoading] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleLoading = useCallback((loading) => {
    setLoading(loading);
  }, []);

  // Hide pop up
  const handleShowConfirmationCollectionCreate = () => {
    onShowConfirmationCollectionCreate(false);
  };

  // Fetch Create Collection
  const handleCreateCollection = async () => {
    const requestData = {
      // Name of Collection
      name: nameCollection,
    };

    fetchCreateNameCollection(
      requestData,
      onNameCollectionError,
      onNameCollectionSuccess,
      onNameCollection,
      onShowConfirmationCollectionCreate,
      onUpdateStampQuota,
      handleLoading,
    );
  };

  return (
    <div className="rb-loading-wrap rb-confirmation-collection-wrap">
      {loading && (
        <div className="rb-loading__loader">
          <motion.span
            animate={{
              rotate: 360,
            }}
            transition={{
              repeat: Infinity,
              duration: 1,
              ease: "linear",
            }}
          />
        </div>
      )}
      <div className="rb-loading__title">
        {loading ? titleLoading : titleConfirmation}
      </div>
      <div className="rb-loading__title">
        {loading ? "" : "Collection Name: " + nameCollection}
      </div>
      <div className="rb-loading__text">
        {loading ? textLoading : textConfirmation}
      </div>

      {!loading && (
        <>
          <div className="rb-loading__title rb-confirmation__title">
            Create new Collection?
          </div>
          <div className="rb-confirmation__buttons rb-display-flex rb-justify-content-space-between">
            <button
              className="button rb-btn rb-btn-black rb-display-flex rb-align-items-center"
              onClick={() => handleCreateCollection()}
            >
              Yes
            </button>
            <button
              className="button rb-btn rb-btn-white rb-display-flex rb-align-items-center"
              onClick={() => handleShowConfirmationCollectionCreate()}
            >
              No
            </button>
          </div>
        </>
      )}
    </div>
  );
};

ConfirmationLoadingCollectionCreating.propTypes = {
  titleConfirmation: PropTypes.string.isRequired,
  textConfirmation: PropTypes.string.isRequired,
  titleLoading: PropTypes.string.isRequired,
  textLoading: PropTypes.string.isRequired,
  nameCollection: PropTypes.string.isRequired,
  onNameCollectionError: PropTypes.func.isRequired,
  onNameCollectionSuccess: PropTypes.func.isRequired,
  onNameCollection: PropTypes.func.isRequired,
  onShowConfirmationCollectionCreate: PropTypes.func.isRequired,
  onUpdateStampQuota: PropTypes.func.isRequired,
};

export default ConfirmationLoadingCollectionCreating;

export const createSubmit = async (
  objectHash,
  setLoading,
  setError,
  handleUpdateHashWorker,
  selectedOptionCollection,
  handleSelectedOptionCollection,
) => {
  setLoading(true);
  console.log("Collection selected: " + selectedOptionCollection?.id);
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  fetch("/stamp/create-stamp/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrfToken,
    },
    body: JSON.stringify({
      "object-hash": objectHash,
      "collection-hash": selectedOptionCollection?.id,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        setError(data);
        setLoading(false);
      } else {
        handleUpdateHashWorker(objectHash, null, true);
        console.log(data);
      }
      handleSelectedOptionCollection("");
    })
    .catch((error) => {
      setLoading(false);
      setError(`Error: ${error.message}`);
      handleSelectedOptionCollection("");
    });
};

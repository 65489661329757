import { formatCollection } from "./formatCollection";
export const URLs = Object.freeze({
  PADDLE_DETAILS_URL: "/paddle/",
  PADDLE_TRANSACTION_STATUS_URL: "/paddle/transaction-status/",
  CONFIG_URL: "/profile/config/",
  REPORTS_PAGE_URL: "/reports/",
  REPORTS_URL: "/reports/data/?page=",
  REPORTS_PRODUCER_URL: "/reports/producer/data/?page=",
  USER_INFO_URL: "/users/user-info/",
  USER_INFO_SAVE_PROFILE_URL: "/users/save-profile/",
  USER_INFO_USER_ADDRESS_HISTORY_URL: "/users/user-address-history/",
  USER_PROFILE_DELETE_ACCOUNT_URL: "/profile/delete-account/",
  PROFILE_API_KEYS_URL: "/profile/api-keys/",
  PROFILE_STATISTICS_URL: "/profile/statistics/",
  PROFILE_PAYMENT_URL: "/profile/payment/",
  PROFILE_COLLECTIONS_URL: "/profile/collections/",
  ACCOUNTS_SIGNUP_URL: "/accounts/signup/",
  ACCOUNTS_LOGIN_URL: "/accounts/login/",
  VERIFY_URL: "/verify/",
  STAMP_URL: "/stamp/",
  PADDLE_CANCEL_URL: "/paddle/cancel/",
  CONTACTS_URL: "https://www.vbase.com/contact/",
  SUBSCRIPTION_SUCCESS_URL: "/paddle/subscription-success/",
  SUBSCRIPTION_FAILED_URL: "/paddle/subscription-failed/",
  USER_FOUND_DATA_URL: "/profile/user-found-data/",
  USER_COLLECTIONS_URL: "/profile/#collections",
  USER_BILLING_URL: "/profile/#billing",
});

export const userFoundData = async (newPage, setInputValues, uidParam) => {
  try {
    const response = await fetch(
      URLs.USER_FOUND_DATA_URL + uidParam + "?page=" + newPage,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    if (data.static_data) {
      setInputValues({
        user_found: true,
        stamp_history: data?.stamp_history || [],
        current_page: data?.current_page || 1,
        total_pages: data?.total_pages || 1,
        persistent_ID: data?.static_data?.persistent_ID || "",
        current_display_name: data?.static_data?.current_display_name || "",
        current_user_ID: data?.static_data?.current_user_ID || "",
        current_account_status:
          data?.static_data?.current_account_status || "Not Verified",
        total_stamps: data?.static_data?.total_stamps || "",
        sets: data?.static_data?.sets || "",
        download_csv: data?.static_data?.download_csv || "",
      });
    } else {
      setInputValues({
        user_found: false,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const paddleDataSuccess = async (setInputValues, setIsLoading) => {
  try {
    const response = await fetch(URLs.PADDLE_DETAILS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const dataList = await response.json();
    const data = dataList[0];
    setInputValues({
      subscription_info: {
        subscription_paddle_id:
          data.subscription_info?.subscription_paddle_id || false,
        last_transaction_paddle_id:
          data.subscription_info.last_transaction_paddle_id || false,
      },
      user_balance_info: {
        expirable_credits: data.user_balance_info.expirable_credits || 0,
        paid_credits: data.user_balance_info.paid_credits || 0,
        total_credits: data.user_balance_info.total_credits || 0,
      },
      subscription_plan: {
        title: data.subscription_plan?.title || false,
        total_quota_limit: data.subscription_plan?.total_quota_limit || 0,
      },
      billing_info: {
        email: data.billing_info?.email || "",
      },
    });
    setIsLoading(false); // Set loading to false when data is fetched
  } catch (error) {
    console.log(error);
    setIsLoading(false); // Handle errors by stopping the loading state
  }
};

export const fetchStatisticsData = async (setInputValues) => {
  try {
    const response = await fetch(URLs.PROFILE_STATISTICS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    setInputValues({
      last_login: data.last_login || "",
      user_joined: data.user_joined || "",
    });
  } catch (error) {
    console.log(error);
  }
};

export const reportsData = async (page = 1, setInputValues) => {
  try {
    const response = await fetch(URLs.REPORTS_URL + page);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    setInputValues({
      allocations: data?.allocations || [],
      current_page: data?.current_page || 1,
      total_pages: data?.total_pages || 1,
      producer_identity: {
        name: data?.static_data?.producer_identity?.name || "",
        url: data?.static_data?.producer_identity?.url || "",
      },
      strategy_name: data?.static_data?.strategy_name || "",
      press_release_pdf: data?.static_data?.press_release_pdf || "",
      validation_information: {
        strategy_commits:
          data?.static_data?.validation_information?.strategy_commits || 0,
        other_strategies_commits:
          data?.static_data?.validation_information?.other_strategies_commits ||
          0,
        total_commits:
          data?.static_data?.validation_information?.total_commits || 0,
      },
      available_for_download:
        data?.static_data?.available_for_download || false,
      download_ZIP: data?.static_data?.download_ZIP || "",
      download_single_CSV: data?.static_data?.download_single_CSV || "",
    });
  } catch (error) {
    console.log(error);
  }
};
export const reportsProducerData = async (page = 1, setInputValues) => {
  try {
    const response = await fetch(URLs.REPORTS_PRODUCER_URL + page);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    setInputValues({
      strategies: data?.strategies || [],
      current_page: data?.current_page || 1,
      total_pages: data?.total_pages || 1,
      producer_identity: {
        name: data?.static_data?.producer_identity?.name || "",
        verified_by: data?.static_data?.producer_identity?.verified_by || "",
      },
      press_release: data?.static_data?.press_release || "",
      validation_information: {
        total_strategies:
          data?.static_data?.validation_information?.total_strategies || 0,
        total_commits:
          data?.static_data?.validation_information?.total_commits || 0,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchConfigData = async () => {
  try {
    const response = await fetch(URLs.CONFIG_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const { PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN } = data;
    return { PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN };
  } catch (error) {
    console.log(error);
    throw error; // In case the caller wants to handle the error
  }
};

// This function fetches quota data from the server and checks if the quota banner should be shown
export const fetchQuotaData = async () => {
  try {
    const response = await fetch(URLs.PADDLE_DETAILS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const quotaUsageInfo = data[0]?.subscription_plan?.total_quota_usage || 0;
    const userBalanceInfo = data[0]?.user_balance_info;
    const subscriptionInfo = data[0]?.subscription_info;
    const subscriptionPlan = data[0]?.subscription_plan;

    // Return the quota usage info and hide state
    return {
      quotaUsageInfo,
      userBalanceInfo,
      subscriptionPlan,
      subscriptionInfo,
    };
  } catch (error) {
    console.log(error);
    throw error; // In case the caller wants to handle the error
  }
};

export const fetchUserData = async (page = 1, setUserAddressHistoryData) => {
  try {
    const response = await fetch(
      URLs.USER_INFO_USER_ADDRESS_HISTORY_URL + "?page=" + page,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    setUserAddressHistoryData(data);
  } catch (error) {
    console.log(error);
  }
};

//Create Name Collection
export const fetchCreateNameCollection = async (
  requestData,
  setNameCollectionError,
  setNameCollectionSuccess,
  setNameCollection,
  onShowConfirmationCollectionCreate,
  onUpdateStampQuota,
  handleLoading,
) => {
  try {
    handleLoading(true);
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(URLs.PROFILE_COLLECTIONS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(requestData),
    });

    const result = await response.json();

    if (response.ok) {
      setNameCollectionError("");
      setNameCollection("");
      setNameCollectionSuccess(
        result.message +
          ": " +
          formatCollection(result.collection.name, result.collection.cid),
      );
      onUpdateStampQuota(Date.now());
      setTimeout(() => {
        setNameCollectionSuccess("");
      }, 3000);

      // Hide pop up
      onShowConfirmationCollectionCreate(false);

      // Hide Loading
      handleLoading(false);
    } else {
      setNameCollectionSuccess("");
      setNameCollectionError(result.error);
      setTimeout(() => {
        setNameCollectionError("");
      }, 3000);
      console.log("Error:", result);

      // Hide pop up
      onShowConfirmationCollectionCreate(false);

      // Hide Loading
      handleLoading(false);
    }
  } catch (error) {
    setNameCollectionSuccess("");
    setNameCollectionError(error.message);
    setTimeout(() => {
      setNameCollectionError("");
    }, 3000);
    console.log(error);

    // Hide pop up
    onShowConfirmationCollectionCreate(false);

    // Hide Loading
    handleLoading(false);
  }
};

//All Collections is pinned
export const fetchAllCollectionsIsPinned = async (page = 1, setInputValues) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL +
        "?page=" +
        page +
        "&is_pinned=true&enable_pagination=true",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      },
    );

    const result = await response.json();

    if (response.ok) {
      setInputValues({
        usercollections: result?.usercollections || [],
        current_page: result?.current_page || 1,
        total_pages: result?.total_pages || 1,
      });
    } else {
      console.log("Error:", result);
    }
  } catch (error) {
    console.log(error);
  }
};

//All Collections is pinned without pagination
export const fetchAllCollectionsIsPinnedWithoutPagination = async (
  handleSelectCollections,
) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL + "?enable_pagination=false&is_pinned=true",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      },
    );

    const result = await response.json();

    if (response.ok) {
      handleSelectCollections(result?.usercollections);
    } else {
      console.log("Error:", result);
    }
  } catch (error) {
    console.log(error);
  }
};

//All Collections is not pinned
export const fetchAllCollectionsIsNotPinned = async (
  page = 1,
  setInputValuesIsNotPinned,
) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL +
        "?page=" +
        page +
        "&is_pinned=false&enable_pagination=true",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      },
    );

    const result = await response.json();

    if (response.ok) {
      setInputValuesIsNotPinned({
        usercollections: result?.usercollections || [],
        current_page: result?.current_page || 1,
        total_pages: result?.total_pages || 1,
      });
    } else {
      console.log("Error:", result);
    }
  } catch (error) {
    console.log(error);
  }
};

// Pin/Unpin Collection
export const fetchIsPinnedCollection = async (
  collection_id,
  isPinned,
  setShowIsPinnedWindow,
  setNameCollectionIsPinned,
) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL + collection_id + "/",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ is_pinned: isPinned }),
      },
    );
    if (response.ok) {
      console.log(response.message);
      setNameCollectionIsPinned(response.url + isPinned);
      setShowIsPinnedWindow(false);

      document.body.style.overflow = "";
    }
  } catch (error) {
    console.log(error);
    setShowIsPinnedWindow(false);
    document.body.style.overflow = "";
  }
};
